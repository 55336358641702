import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';

import Groups from "./Views/Groups";
import Users from "./Views/Users";
import Settings from "./Views/Settings";
import Groupinfo from "./Views/Groupinfo";
import Userinfo from "./Views/Userinfo";
import NewUser from './Views/NewUser'
import MainView from './Views/MainView'

import "@aws-amplify/ui-react/styles.css";
import {
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";

const theme = createTheme({
  palette: {
    primary: {
      main: '#00B6CD',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#b1ebf4'
    },
    custom: {
      main: '#00C1B3',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#FFFFFF'
    }
  }
});

function App({ signOut }) {
  return (
    <ThemeProvider theme={theme}>
      <View className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<MainView signOut={signOut} />} />
            <Route exact path="/groups" element={<Groups />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="groupinfo/:group" element={<Groupinfo />} />
            <Route exact path="userinfo/:user" element={<Userinfo />} />
            <Route exact path="/newuser" element={<NewUser />} />
          </Routes>
        </Router>
      </View>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);

