import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Auth } from 'aws-amplify';

import logo from '../assets/simplyLogo@4x.png'

import Header from '../Components/Header'
import TabPanelEmployee from '../Components/TabPanelEmployee'
import TabPanelAdminBHSS from '../Components/TabPanelAdminBHSS'
import TabPanelAdminRKHR from '../Components/TabPanelAdminRKHR'

function MainView({ signOut }) {
  const [value, setValue] = useState(0);
  const [userGroup, setUserGroup] = useState('');

  useEffect(() => {
    getUserData();
    sendFeedbackRequest();
  }, []);

  const sendFeedbackRequest = async () => {
    const patientInfo = {
      userId: "103189"
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
      },
      body: JSON.stringify(patientInfo)
    };

    const response = await fetch(`https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getFeedbackByUser`, requestOptions);
    const json = await response.json();

    const patients = json.Item.lastUpdateTime;
  };

  const getUserData = async () => {
    let groupNr = 0;
    let user = await Auth.currentAuthenticatedUser();
    groupNr = user.attributes.locale;
    setUserGroup(groupNr);
  };

  const ShowTab = () => {
    const tabs = {
      '1': ['2C', '2D', '2E', '1B', 'TEST', 'Pasienter', 'Ansatte', 'Evaluering'],
      '3': ['Grønn', 'Pasienter', 'Ansatte'],
      '1B': ['1B'],
      '2C': ['2C'],
      '2D': ['2D'],
      '2E': ['2E'],
      'Gronn': ['Grønn']
    };

    return (
      <Tabs
        textColor='secondary'
        TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabs[userGroup]?.map((label, index) => (
          <Tab key={index} label={label} {...a11yProps(index)} />
        ))}
      </Tabs>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <Box sx={{ flexDirection: 'row', flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 800 }}>
      <Box sx={{ bgcolor: '#51B7CE', height: '100%' }}>
        <Box sx={{ height: 100, width: '100%', paddingTop: '15%', marginBottom: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img style={{ width: '32%', height: '70%' }} src={logo} alt="Logo" />
        </Box>
        <ShowTab />
      </Box>
      <Box sx={{ flexDirection: 'row', width: '100%' }}>
        <Header signOut={signOut} />
        {userGroup === '1' && <TabPanelAdminBHSS value={value} />}
        {userGroup === '3' && <TabPanelAdminRKHR value={value} />}
        {(userGroup === '1B' || userGroup === '2C' || userGroup === '2D' || userGroup === '2E' || userGroup === 'Gronn') && <TabPanelEmployee value={value} userGroup={userGroup} />}
      </Box>
    </Box>
  );
}

MainView.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default MainView;
