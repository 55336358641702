import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Button as MaterialButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  Select,
  TextField,
  useTheme
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { Auth } from 'aws-amplify';
import UserInfoDrawer from "../Overlays/UserInfoDrawer";
import { requestFeedback, getAllFeedbacksByGroup } from "../../services/UserService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const activities = {
  'Ri': 'Hvordan var det å ri på hest i dag?',
  'Klatring': 'Hvordan var det å klatre i dag?',
  'Basseng': 'Hvordan var det å være i bassenget i dag?',
  'Aktivitet gymsal': 'Hvordan var det med aktivitet i gymsal i dag?',
  'Sykling': 'Hvordan var det å sykle i dag?',
  'Være på tur': 'Hvordan var det å være på tur i dag?',
  'Friluftsliv': 'Hvordan var det å drive med friluftsliv i dag?',
  'Alpin': 'Hvordan var det å drive med alpint i dag?',
  'Skøyter': 'Hvordan var det å skøyte i dag?',
  'Langrenn': 'Hvordan var det å drive med langrenn i dag?',
  'Utendørs vann': 'Hvordan var det å drive med vannaktivitet ute i dag?',
  'Aktivitet skjerm': 'Hvordan var det å drive med aktivitet på skjerm i dag?',
  'Leke ute': 'Hvordan var det å leke ute i dag?',
  'Bevegelse musikk': 'Hvordan var det å bevege seg til musikk i dag?'
}

const activityMapping = {
  'Ri': 'horse',
  'Klatring': 'climbing',
  'Basseng': 'pool',
  'Aktivitet gymsal': 'gym',
  'Sykling': 'bicycle',
  'Være på tur': 'hike',
  'Friluftsliv': 'outdoor',
  'Alpin': 'alpine',
  'Skøyter': 'skates',
  'Langrenn': 'skiing',
  'Utendørs vann': 'canoe',
  'Aktivitet skjerm': 'screen',
  'Leke ute': 'playOutside',
  'Bevegelse musikk': 'musicMove'
};

const reverseActivityMapping = Object.fromEntries(
  Object.entries(activityMapping).map(([key, value]) => [value, key])
);

const feedbackColumns = [
  { field: 'timeOfCreation', headerName: 'Dato', width: 100, type: 'dateTime', valueFormatter: ({ value }) => formatDate(value) },
  {
    field: 'activity',
    headerName: 'Aktivitet',
    width: 150,
    valueFormatter: ({ value }) => reverseActivityMapping[value] || value
  },
  { field: 'question', headerName: 'Spørsmål', width: 350 },
  {
    field: 'feedbackValues',
    headerName: 'Feedback Values',
    width: 300,
    renderCell: (params) => (
      <div>
        {params.value.map((val, index) => (
          <div key={index}>
            User ID: {val.userId}, Svar: {toText(val.value)}
          </div>
        ))}
      </div>
    ),
  },
];

const generalColumns = [
  { field: 'userId', headerName: 'PasientID', width: 100 },
  { field: 'firstName', headerName: 'Fornavn', width: 120 },
  {
    field: 'arrivalDate', headerName: 'Ankomst', type: 'dateTime', width: 90, valueFormatter: ({ value }) => formatDate(value),
  },
  ...[
    { field: 'horse', label: 'Ri' },
    { field: 'climbing', label: 'Klatring' },
    { field: 'pool', label: 'Basseng' },
    { field: 'gym', label: 'Aktivitet gymsal' },
    { field: 'cycle', label: 'Sykling' },
    { field: 'hike', label: 'Være på tur' },
    { field: 'outdoor', label: 'Friluftsliv' },
    { field: 'alpin', label: 'Alpin' },
    { field: 'skates', label: 'Skøyter' },
    { field: 'skiing', label: 'Langrenn' },
    { field: 'canoe', label: 'Utendørs vann' },
    { field: 'screen', label: 'Aktivitet skjerm' },
    { field: 'playOutside', label: 'Leke ute' },
    { field: 'musicMove', label: 'Bevegelse musikk' }
  ].map(activity => ({
    field: activity.field,
    headerName: activity.label,
    width: 90,
    valueGetter: ({ row }) => toText(row[activity.field]),
  }))
];

const rkhrColumns = [
  { field: 'userId', headerName: 'PasientID', width: 140 },
  { field: 'firstName', headerName: 'Fornavn', width: 120 },
  {
    field: 'arrivalDate', headerName: 'Ankomst', type: 'dateTime', width: 90, valueFormatter: ({ value }) => formatDate(value),
  },
  ...[
    { field: 'rkhr_bowarrow', label: 'Pil og bue' },
    { field: 'rkhr_climbing', label: 'Klatring' },
    { field: 'rkhr_cooking', label: 'Matlaging' },
    { field: 'rkhr_cycling', label: 'Sykling' },
    { field: 'rkhr_gym', label: 'Gym' },
    { field: 'rkhr_handicrafts', label: 'Håndarbeid' },
    { field: 'rkhr_outdoor', label: 'Friluftsliv' },
    { field: 'rkhr_pool', label: 'Basseng' },
    { field: 'rkhr_sea', label: 'Aktivitet sjø' },
    { field: 'rkhr_shooting', label: 'Skyting' },
    { field: 'rkhr_tabletennis', label: 'Bordtennis' },
    { field: 'rkhr_vr', label: 'VR' }
  ].map(activity => ({
    field: activity.field,
    headerName: activity.label,
    width: 90,
    valueGetter: ({ row }) => toText(row[activity.field]),
  }))
];

const formatDate = (date) => {
  const newDate = new Date(date);
  return `${newDate.getDate()}.${newDate.getMonth() + 1}.${newDate.getFullYear()}`;
};

const toText = (value) => {
  switch (value) {
    case 1: return 'Ikke gøy';
    case 2: return 'Skummelt';
    case 3: return 'Litt gøy';
    case 4: return 'Veldig gøy';
    case 5: return 'Vet ikke';
    default: return '';
  }
};

function getStyles(activity, activityName, theme) {
  return {
    fontWeight: activityName.indexOf(activity) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
}

const UsersDataGrid = ({ groupNr }) => {
  const [data, setData] = useState([]);
  const [shownData, setShownData] = useState([]);
  const [user, setUser] = useState([]);
  const [value, setValue] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const [activityName, setActivityName] = useState('');
  const [instColumns, setInstColumns] = useState(generalColumns);
  const [instID, setInstID] = useState('01');
  const theme = useTheme();
  const [activityQuestion, setActivityQuestion] = useState('');
  const [error, setError] = useState(false);
  const [currentArrivalDate, setCurrentArrivalDate] = useState(null);
  const [signedInUser, setSignedInUser] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [todayFeedbacks, setTodayFeedbacks] = useState([]);
  const [seeFeedbacks, setSeeFeedbacks] = useState(false);
  const [feedbackTabValue, setFeedbackTabValue] = useState(0);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const inst = user.attributes.zoneinfo;
      setInstColumns(inst === '01' ? generalColumns : rkhrColumns);
      setInstID(inst);
      setUsers(groupNr);
      setSignedInUser(user);
      getFeedbacksByGroup();
    })();
  }, [groupNr]);

  const setUsers = async (groupNr) => {
    const response = await fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getusersbygroup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': 'XB0AKjyrCloFsXp4AJ0e8h0VK2VJkhB7t2430jia' },
      body: JSON.stringify({ groupId: groupNr })
    });
    const result = await response.json();

    setShownData(result.Items);
    setData(result.Items);

    const currentUsers = result.Items.filter(user =>
      new Date(user.arrivalDate).getTime() <= new Date().getTime() &&
      new Date(user.arrivalDate).getTime() + 20 * 86400000 > new Date().getTime()
    );

    if (currentUsers.length > 0) {
      const arrivalDate = new Date(currentUsers[0].arrivalDate).toLocaleDateString();
      setCurrentArrivalDate(arrivalDate);
    } else {
      setCurrentArrivalDate(null);
    }
  };


  // Call this after fetching feedbacks
  const getFeedbacksByGroup = async () => {
    try {
      const feedbackData = await getAllFeedbacksByGroup(groupNr, instID);
      console.log("Fetched Feedback Data:", feedbackData); // Debugging line

      if (feedbackData) {
        const fetchedFeedbacks = feedbackData.feedbacks || [];

        console.log("Fetched Feedbacks:", fetchedFeedbacks);

        // Transform fetched feedbacks to include nested feedback values
        const transformedFeedbacks = fetchedFeedbacks.map(feedback => ({
          ...feedback,
          feedbackValues: feedback.feedbackValues || [], // Ensure feedbackValues is an array
        }));

        setFeedbacks(transformedFeedbacks);

        const filteredTodayFeedbacks = filterFeedbacksByToday(transformedFeedbacks);
        console.log("Filtered Today's Feedbacks:", filteredTodayFeedbacks); // Debugging line

        setTodayFeedbacks(filteredTodayFeedbacks);

        if (!filteredTodayFeedbacks.some(feedback => feedback.activity === activityMapping[activityName])) {
          setActivityName(''); // Reset if current activityName is invalid
        }
      } else {
        console.error("No feedback data received."); // Error handling
      }
    } catch (error) {
      console.error("Error fetching feedbacks:", error); // Error handling
    }

    filterFeedbacksByTab(feedbackTabValue);
  };


  const filterFeedbacksByToday = (feedbacks) => {
    const today = new Date();
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
    const endOfToday = startOfToday + 24 * 60 * 60 * 1000;

    return feedbacks.filter(feedback => {
      return feedback.timeOfCreation >= startOfToday && feedback.timeOfCreation < endOfToday;
    });
  };

  const filterFeedbacksByTab = (tabIndex) => {
    const today = new Date();
    let filtered;

    switch (tabIndex) {
      case 0: // Today
        const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
        const endOfToday = startOfToday + 24 * 60 * 60 * 1000; // End of today
        filtered = feedbacks.filter(feedback => feedback.timeOfCreation >= startOfToday && feedback.timeOfCreation < endOfToday);
        break;
      case 1: // Yesterday
        const startOfYesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).getTime();
        const endOfYesterday = startOfYesterday + 24 * 60 * 60 * 1000; // End of yesterday
        filtered = feedbacks.filter(feedback => feedback.timeOfCreation >= startOfYesterday && feedback.timeOfCreation < endOfYesterday);
        break;
      case 2: // Last Week
        const startOfLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime(); // Start of last week
        const endOfLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).getTime(); // End of yesterday
        filtered = feedbacks.filter(feedback => feedback.timeOfCreation >= startOfLastWeek && feedback.timeOfCreation < endOfLastWeek);
        break;
      default:
        filtered = feedbacks;
    }

    setFilteredFeedbacks(filtered);
  };


  const handleFeedbackTabChange = (event, newValue) => {
    setFeedbackTabValue(newValue);
    filterFeedbacksByTab(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) filterUsersByCondition(isPreviousUser);
    else if (newValue === 1) filterUsersByCondition(isCurrentUser);
    else filterUsersByCondition(isFutureUser);
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setActivityName(value);
    setActivityQuestion(activities[value]);
    setError(false);
  };

  const filterUsersByCondition = (condition) => {
    setData(shownData.filter(condition));
  };

  const isPreviousUser = (user) => new Date(user.arrivalDate).getTime() < new Date().getTime() - 20 * 86400000;
  const isCurrentUser = (user) => new Date(user.arrivalDate).getTime() <= new Date().getTime() &&
    new Date(user.arrivalDate).getTime() + 20 * 86400000 > new Date().getTime();
  const isFutureUser = (user) => new Date(user.arrivalDate).getTime() > new Date().getTime();

  const handleRowClick = (event) => {
    setDrawer(true);
    setUser(event.row);
  };

  function ShowDrawer(userInfo) {

    if (drawer) {
      console.log("Userinfo " + user.firstName)
      return (
        <UserInfoDrawer open={drawer} user={user} instID={instID} />
      )

    } else {
      return (
        null
      )
    }
  }

  const handleCreateFeedback = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    if (!activityName) {
      setError(true);
      return;
    } else {
      setError(false);
      setOpen(false);

      const user = signedInUser;

      const activityField = activityMapping[activityName];

      let feedback_object = {
        instId: user.attributes.zoneinfo,
        arrivalDate: currentArrivalDate,
        groupId: groupNr,
        question: activityQuestion,
        activity: activityField,
        timeOfCreation: new Date().getTime()
      };

      // Save the feedback
      await requestFeedback(feedback_object);

      // Refresh feedbacks to include the newly created one
      await getFeedbacksByGroup();

      setActivityName('');
      setActivityQuestion('');
    }
  };

  const handleQuestionChange = (event) => {
    setActivityQuestion(event.target.value);
  }

  const handleSeeFeedbacks = () => {
    setSeeFeedbacks(true);
  }

  const handleCloseFeedbacks = () => {
    setSeeFeedbacks(false);
  }

  const toggleRow = (id) => {
    setExpandedRowIds((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  return (
    <Box sx={{ flexDirection: 'column', width: '100%', display: 'flex', alignItems: 'flex-start' }}>
      {seeFeedbacks ? (
        // Feedback Grid with Date Filtering Tabs
        <Box sx={{ flexDirection: 'column', width: '100%', display: 'flex', alignItems: 'flex-start' }}>
          <Box sx={{ borderBottom: 2, borderColor: 'divider', bgcolor: 'background.paper', width: '100%', height: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingRight: '2%' }}>
            <Tabs value={feedbackTabValue} onChange={handleFeedbackTabChange} centered>
              <Tab label="I dag" />
              <Tab label="I går" />
              <Tab label="Siste uke" />
            </Tabs>
            <MaterialButton sx={{ marginLeft: 2 }} color="custom" variant="contained" onClick={handleCloseFeedbacks}>
              Se brukere
            </MaterialButton>
          </Box>

          <Box sx={{ height: 630, width: '100%', paddingTop: 6 }}>
            <DataGrid
              getRowId={(row) => row.feedbackId} // Assuming id is unique for feedback
              rows={filteredFeedbacks} // Display filtered feedbacks here
              columns={feedbackColumns} // Customize the columns for feedback display
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
            />
          </Box>
        </Box>
      ) : (
          // User Data View
          <Box sx={{ flexDirection: 'column', width: '100%', display: 'flex', alignItems: 'flex-start' }}>
            <Box sx={{ borderBottom: 2, borderColor: 'divider', bgcolor: 'background.paper', width: '100%', height: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingRight: '2%' }}>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Tidligere" />
                <Tab label="Nåværende" />
                <Tab label="Kommende" />
              </Tabs>
              <MaterialButton sx={{ marginLeft: 2 }} color="custom" variant="contained" onClick={handleCreateFeedback}>
                Ny evaluering
            </MaterialButton>
              <MaterialButton sx={{ marginLeft: 2 }} color="custom" variant="contained" onClick={handleSeeFeedbacks}>
                Se evalueringer
            </MaterialButton>
            </Box>

            <Box sx={{ height: 630, width: '100%', paddingTop: 6 }}>
              <DataGrid
                getRowId={(row) => row.userId} // Use appropriate user ID field
                onRowClick={handleRowClick}
                rows={data} // Original data for user display
                columns={instColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
              />
              <ShowDrawer
                user={user} />
            </Box>
          </Box>
        )}

      {/* Dialog for creating new feedback */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ny evaluering</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Velg aktiviteten du vil brukerene skal evaluere.
          </DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <FormControl fullWidth sx={{ my: 2, minWidth: 440 }} required error={error}>
              <InputLabel id="multiple-activity-label">Aktivitet</InputLabel>
              <Select
                labelId="multiple-activity-label"
                value={activityName || ''}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Aktivitet" />}
                MenuProps={MenuProps}
              >
                {Object.keys(activities).filter(key => {
                  // Exclude activities that exist in today's feedbacks
                  return !todayFeedbacks.some(feedback => feedback.activity === activityMapping[key]);
                }).map((key) => (
                  <MenuItem key={key} value={key} style={getStyles(key, activityName, theme)}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>Vennligst velg en aktivitet</FormHelperText>} {/* Error message */}
            </FormControl>

            <TextField
              fullWidth
              sx={{ my: 2 }}
              value={activityQuestion}
              onChange={handleQuestionChange}
              autoFocus
              margin="dense"
              label="Spørsmål"
              type="text"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleClose}>Avbryt</MaterialButton>
          <MaterialButton onClick={handleSave}>Send</MaterialButton>
        </DialogActions>
      </Dialog>
      <UserInfoDrawer open={drawer} setOpen={setDrawer} user={user} />
    </Box>
  );

};

export default UsersDataGrid;