import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';

export default function Header({ signOut }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickSettings = (event) => {
    //TODO 1) own name 2) Settings
    setAnchorEl(event.currentTarget);
  };

  const handleClickNotification = (event) => {
    //TODO badge when new notification
  };

  const handleClickHelp = (event) => {
    //TODO add help notes
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          borderBottom: 2,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          width: '100%',
          height: '10%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          textAlign: 'center',
          paddingRight: '2%',
        }}
      >
        <Tooltip title="Hjelp">
          <Typography onClick={handleClickHelp} sx={{ minWidth: 100 }}>Hjelp</Typography>
        </Tooltip>
        <Tooltip title="Varsler">
          <IconButton onClick={handleClickNotification} size="small" sx={{ ml: 2 }}>
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Kontoinstillinger">
          <IconButton onClick={handleClickSettings} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 32, height: 32 }}>R</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logg ut
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
