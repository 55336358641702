import React from 'react';
import View from '@mui/material/Box';
import TabPanel from './TabPanel';
import UserDataGrid from './Grids/UserDataGrid';

const TabPanelEmployee = ({ value, userGroup }) => (
    <View>
        <TabPanel value={value} index={0}>
            <UserDataGrid groupNr={userGroup} />
        </TabPanel>
    </View>
);

export default TabPanelEmployee;
