import React, { useMemo, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MaterialButton from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getEvaluations } from "../services/UserService";
import { DataGrid } from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import { toText } from "./UserColumns";

const columnsChild = [
    { field: 'childEvaluationAnswer1', headerName: "Hvordan har ditt opphold på helsesportsenteret vært?", width: 400 },
    { field: 'childEvaluationAnswer2', headerName: "Har du lært noe nytt mens du har vært på helsesportsenteret?", width: 400 },
    { field: 'childEvaluationAnswer3', headerName: "Hvordan har det vært å være i en gruppe sammen med andre?", width: 400 },
];

const columnsParent = [
    { field: 'parentEvaluationAnswer1', headerName: "Hvor tilfreds er dere med informasjonen dere fikk i forkant av oppholdet?", width: 200 },
    { field: 'parentEvaluationAnswer2', headerName: "Hvor tilfreds er dere med informasjonen dere har fått i løpet av oppholdet?", width: 200 },
    { field: 'parentEvaluationAnswer3', headerName: "Var dere delaktige i utforming av målsetting for oppholdet?", width: 200 },
    { field: 'parentEvaluationAnswer4', headerName: "Var aktivitetstilbudet godt tilrettelagt?", width: 200 },
    { field: 'parentEvaluationAnswer5', headerName: "Har det vært fokus på oppfølging i etterkant av oppholdet?", width: 200 },
    { field: 'parentEvaluationAnswer6', headerName: "Hvor tilfreds er dere med det generelle utbyttet av oppholdet?", width: 200 },
];

export default function Evaluation() {

    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [group, setGroup] = React.useState('');
    const [shownData, setShownData] = useState([])
    const [value, setValue] = useState(0);
    const [columns, setColumns] = useState(columnsChild);

    const selectHandleChange = (event) => {
        setGroup(event.target.value);
    };


    const toText = (value) => {
        switch (value) {
            case "1":
                return 'Ikke gøy'
            case "2":
                return 'Skummelt'
            case "3":
                return 'Litt gøy'
            case "4":
                return 'Veldig gøy'
            case "5":
                return 'Vet ikke'
            default:
                return ''
        }
    }

    const toTextParent = (value) => {
        switch (value) {
            case "1":
                return 'Veldig utilfreds'
            case "2":
                return 'Litt utilfreds'
            case "3":
                return 'Vet ikke'
            case "4":
                return 'Litt tilfreds'
            case "5":
                return 'Veldig tilfreds'
            default:
                return ''
        }
    }

    const getEvaluations = async (groupInfo) => {
        let items = null

        let object = {
            "arrivalDate": arrivalDate,
            "groupId": group
        }
        const body = JSON.stringify(object)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'k3z6bi0JJj73fPtTkXcTkI4QVJf4mFf7UbalzTp5'
            },
            body: body
        };
        fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getEvaluations', requestOptions)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                console.log(data);
                items = data;
                setShownData(data)
                console.log("data " + data[1].arrivalDate)
            });
    }

    const processedData = shownData.map((item) => {
        const childEval = item.childEvaluation || [];
        const childEvalAnswers = childEval.map((evalItem) => toText(evalItem.Answer));
        const parentEval = item.parentEvaluation || [];
        const parentEvalAnswers = parentEval.map((evalItem) => toTextParent(evalItem.Answer));
        return {
            ...item,
            childEvaluationAnswer1: childEvalAnswers[0], // Accessing the first child evaluation answer
            childEvaluationAnswer2: childEvalAnswers[1], // Accessing the second child evaluation answer
            childEvaluationAnswer3: childEvalAnswers[2], // Accessing the third child evaluation answer
            parentEvaluationAnswer1: parentEvalAnswers[0], // Accessing the first child evaluation answer
            parentEvaluationAnswer2: parentEvalAnswers[1], // Accessing the first child evaluation answer
            parentEvaluationAnswer3: parentEvalAnswers[2], // Accessing the first child evaluation answer
            parentEvaluationAnswer4: parentEvalAnswers[3], // Accessing the first child evaluation answer
            parentEvaluationAnswer5: parentEvalAnswers[4], // Accessing the first child evaluation answer
            parentEvaluationAnswer6: parentEvalAnswers[5], // Accessing the first child evaluation answer
        };
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);

        switch (newValue) {
            case 0: setColumns(columnsChild)
                break;
            case 1: setColumns(columnsParent)
        }
    };





    return (
        <Box>
            <Box
                sx={{ flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Typography variant="h3"
                    sx={{ paddingTop: 6 }}>
                    Evaluering</Typography>
            </Box>
            <Typography variant="body1"
                sx={{ marginRight: 0, paddingTop: 4 }}>
                Legg inn ankomstdato og gruppenummer for å hente evaluering</Typography>
            <Box sx={{ marginLeft: 20, paddingTop: 2, flexDirection: 'row', width: '60%', justifyContent: 'right', alignItems: 'right', display: 'flex' }}>
                <Box sx={{ marginRight: 0, flexDirection: 'row', width: '50%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <DatePicker selected={arrivalDate} onChange={(date) => setArrivalDate(date)} />
                </Box>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-simple-select-label">Gruppe</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={group}
                        label="Gruppe"
                        onChange={selectHandleChange}
                    >
                        <MenuItem value={"2C"}>2C</MenuItem>
                        <MenuItem value={"2D"}>2D</MenuItem>
                        <MenuItem value={"2E"}>2E</MenuItem>
                        <MenuItem value={"1B"}>1B</MenuItem>
                        <MenuItem value={"TEST"}>TEST</MenuItem>
                    </Select>
                </FormControl>
                <MaterialButton
                    sx={{ m: 1, minWidth: 120, marginLeft: 4, }}
                    color="custom"
                    onClick={getEvaluations}
                    variant="contained">
                    Hent evaluering
      </MaterialButton>
            </Box>
            <Container>
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label="Barn" />
                    <Tab label="Foresatte" />
                </Tabs>
            </Container>

            <Box sx={{ height: 420, width: '100%', paddingTop: 4 }}>
                <DataGrid
                    getRowId={(row) => row.userId}
                    rows={processedData}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}

                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Box>
        </Box>
    )
}