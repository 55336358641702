import React from 'react';
import { node } from 'prop-types';


export const getEvaluations = async (groupInfo) => {
    let items = null

    let object = {
        "arrivalDate": "2023-11-29T03:01:58.000Z",
        "groupId": "2C"
    }
    const body = JSON.stringify(object)

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'k3z6bi0JJj73fPtTkXcTkI4QVJf4mFf7UbalzTp5'
        },
        body: body
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getEvaluations', requestOptions)
        .then(function (response) {
            return response.json();
        }).then(function (data) {
            console.log(data);
            items = data;
        });
    return items
}

export const getAllFeedbacksByGroup = async (groupId, instId) => {
    const object = {
        groupId: groupId,
        instId: instId
    };
    const body = JSON.stringify(object);
    console.log("body: " + body);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
        },
        body: body
    };

    try {
        const response = await fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllFeedbacksByGroup', requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        console.log(data);
        return data;  // Return the data directly
    } catch (error) {
        console.error("Error fetching feedbacks:", error);
        return null;  // Handle errors appropriately
    }
};

export const post = async (user) => {
    const postUser = JSON.stringify(user)

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
        },
        body: postUser
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/userHandlerAdmin', requestOptions)
        .then(response => console.log(response.status))
}

export const postEmployee = async (user, requestMethod = 'POST') => {

    const postUser = JSON.stringify(user)

    const requestOptions = {
        method: requestMethod,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
        },
        body: postUser
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/handleUserPool', requestOptions)
        .then(response => console.log(response.status))
}

export const requestFeedback = async (feedback, requestMethod = 'POST') => {

    const postFeedback = JSON.stringify(feedback)

    const requestOptions = {
        method: requestMethod,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
        },
        body: postFeedback
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/requestFeedback', requestOptions)
        .then(response => console.log(response.status))
}

export const getUsers = async => {
    let items = null

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ',
        }
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllUsers', requestOptions)
        .then(function (response) {
            return response.json();
        }).then(function (data) {
            console.log(data.Items);
            items = data.Items;
        });
    return items
}