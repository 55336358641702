import React from 'react';
import View from '@mui/material/Box';
import TabPanel from './TabPanel';
import UserDataGrid from './Grids/UserDataGrid';
import AdminView from '../Views/AdminView';
import EmployeesView from '../Views/EmployeesView';
import Evaluations from '../Views/Evaluations';

const TabPanelAdminBHSS = ({ value }) => (
    <View>
        <TabPanel value={value} index={0}>
            <UserDataGrid groupNr={'2C'} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <UserDataGrid groupNr={'2D'} />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <UserDataGrid groupNr={'2E'} />
        </TabPanel>
        <TabPanel value={value} index={3}>
            <UserDataGrid groupNr={'1B'} />
        </TabPanel>
        <TabPanel value={value} index={4}>
            <UserDataGrid groupNr={'TEST'} />
        </TabPanel>
        <TabPanel value={value} index={5}>
            <AdminView />
        </TabPanel>
        <TabPanel value={value} index={6}>
            <EmployeesView />
        </TabPanel>
        <TabPanel value={value} index={7}>
            <Evaluations />
        </TabPanel>
    </View>
);

export default TabPanelAdminBHSS;
