import React from 'react';
import View from '@mui/material/Box';
import TabPanel from './TabPanel';
import UserDataGrid from './Grids/UserDataGrid';
import AdminView from '../Views/AdminView';
import EmployeesView from '../Views/EmployeesView';

const TabPanelAdminRKHR = ({ value }) => (
    <View>
        <TabPanel value={value} index={0}>
            <UserDataGrid groupNr={'Gronn'} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <AdminView />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <EmployeesView />
        </TabPanel>
    </View>
);

export default TabPanelAdminRKHR;
