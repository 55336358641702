import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MaterialButton from '@mui/material/Button';


export default function UserInfoDrawer(props) {
  const [photo, setPhoto] = useState()
  const [open, setOpen] = useState(props.open)
  const [user, setUser] = useState(props.user)
  const [shownRows, setShownRows] = useState([])


  useEffect(() => {
    if (props.instID === '01') {
      setShownRows(rows)
    } else if (props.instID === '02') {
      setShownRows(rowsRKHR)
    }
  }, []);


  function createData(name, calories) {
    return { name, calories };
  }

  const toText = (value) => {
    switch (value) {
      case 1:
        return 'Ikke gøy'
      case 2:
        return 'Skummelt'
      case 3:
        return 'Litt gøy'
      case 4:
        return 'Veldig gøy'
      case 5:
        return 'Vet ikke'
      default:
        return ''
    }
  }


  const getPhoto = async () => {
    let userInfo = {
      userId: user.userId
    }

    console.log("Body " + JSON.stringify(userInfo))

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'XB0AKjyrCloFsXp4AJ0e8h0VK2VJkhB7t2430jia',
      },
      body: JSON.stringify(userInfo)
    };

    const response = await fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getdoorsign', requestOptions)
    if (response.ok) {
      const json = await response.json()
      if (json.startsWith('https')) {
        window.open(json, '_blank', 'noreferrer')
      }
    } else {
      setPhoto(1)

    }
  }

  const formatDate = (date) => {


    var newDate = new Date(date)

    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let format = day + "." + month + "." + year

    return format;

  }

  function ShowPhoto() {
    if (photo === 1) {
      return (
        <Box sx={{ width: '22%' }}>
          <Typography>Ikke generert dørskilt</Typography>
        </Box>
      )
    } else {
      return null
    }
  }


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(false)
  };

  const rows = [
    createData('Ri på hest', toText(user.horse)),
    createData('Klatring', toText(user.climbing)),
    createData('Basseng', toText(user.pool)),
    createData('Gymsal', toText(user.gym)),
    createData('Være på tur', toText(user.hike)),
    createData('Friluftsliv', toText(user.outdoor)),
    createData('Alpin', toText(user.alpin)),
    createData('Skøyter', toText(user.skates)),
    createData('Langrenn', toText(user.skiing)),
    createData('Aktivitet skjerm', toText(user.screen)),
    createData('Leke ute', toText(user.playOutside)),
    createData('Bevegelse musikk', toText(user.musicMove)),
    createData('Utendørs vann', toText(user.canoe)),
    createData('Sykle', toText(user.cycle)),
  ];
  const rowsRKHR = [
    createData('Matlaging', toText(user.rkhr_cooking)),
    createData('Klatring', toText(user.rkhr_climbing)),
    createData('Basseng', toText(user.rkhr_pool)),
    createData('Gymsal', toText(user.rkhr_gym)),
    createData('Håndarbeid', toText(user.rkhr_handicrafts)),
    createData('Friluftsliv', toText(user.rkhr_outdoor)),
    createData('Skyting', toText(user.rkhr_shooting)),
    createData('VR', toText(user.rkhr_vr)),
    createData('Bordtennis', toText(user.rkhr_tabletennis)),
    createData('Pil og bue', toText(user.rkhr_bowarrow)),
    createData('Aktivitet vann', toText(user.rkhr_sea)),
    createData('Sykle', toText(user.rkhr_cycling)),
  ];

  return (
    <Drawer
      open={open}
      anchor={"right"}
      onClose={toggleDrawer()}
    >
      <Box
        sx={{ flexDirection: 'column', width: 700, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Typography variant="h3"
          sx={{ paddingTop: 6 }}>
          {user.firstName}</Typography>
        <Typography variant="h5"
          sx={{ paddingTop: 2 }}>
          {user.userId}</Typography>
        <Typography variant="h6"
          sx={{ paddingTop: 4 }}>
          Ankomst: {formatDate(user.arrivalDate)}</Typography>
        <MaterialButton onClick={getPhoto}>Hent dørskilt</MaterialButton>
        <ShowPhoto justifyContent="center" />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200, maxWidth: 600, marginLeft: 2, marginRight: 2 }} >

          <TableRow>
            <TableCell>Aktivitet</TableCell>
            <TableCell>Svar</TableCell>
          </TableRow>

          <TableBody>
            {shownRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Drawer>
  )

}